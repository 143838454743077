export const SUBMIT_CONTACT_FORM_SUCCESS = "SUBMIT_CONTACT_FORM_SUCCESS";
export const SUBMIT_CONTACT_FORM_FAILURE = "SUBMIT_CONTACT_FORM_FAILURE";

export const contactUsSuccess = (data) => {
  return {
    type: SUBMIT_CONTACT_FORM_SUCCESS,
    payload:data,
  };
};

export const contactUsFailure = (data) => {
  return {
    type: SUBMIT_CONTACT_FORM_SUCCESS,
    payload:data,
  };
};
