import React, { useState, useEffect } from "react";
import { GoChevronUp } from "react-icons/go";
import "animate.css";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="scroll-to-top ">
      {isVisible && (
        <div
          className={`  
                       hover:text-[#9fe870] hover:bg-[#173303]  rounded-lg 
                        shadow-lg cursor-pointer bg-[#9fe870] text-[#173303]
                        bottom-24 right-[24px] fixed 
                        md:bottom-24 md:right-[24px] 
                        lg:bottom-32 lg:right-[24px]
                        ${
                          isVisible
                            ? "block animate__animated animate__bounce  animate__repeat-2"
                            : "hidden" }`} onClick={scrollToTop}>
          <GoChevronUp size="30px" className="" />
        </div>
      )}
    </div>
  );
};

export default ScrollToTop;
